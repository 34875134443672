import React from 'react';
import AuthService from '../services/auth-service';
import {Route, Redirect} from 'react-router-dom';

const authService = new AuthService();
export const PrivateRoute = ({component: Component, ...rest}) => (

  <Route {...rest} render={(props) => {
    if (!authService.isAuthenticated()) {
      return (<Redirect to='/login'/>);
    }
    return (<Component {...props} />);
  }
  }/>
);

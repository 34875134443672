import React, {Component} from 'react';
import Layout from '../../layouts/layout';
import Spinner from '../../utils/spinner';
import LevelsAnalytics from './analytics';
import LevelsReactions from './reactions';

class Divisions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <Layout title="Divisions">
        {this.state.loading && <Spinner/>}
        <div className="card-header px-0 py-2 bg-white border-0">
          <ul className="nav nav-tabs" id="tab">
            <li className="nav-item">
              <a
                className="btn-link nav-link active"
                id="general-tab"
                data-toggle="tab"
                href="#analytics"
              >
                                Analytics
              </a>
            </li>
            <li className="nav-item">
              <a
                className="btn-link nav-link"
                id="reactions-tab"
                data-toggle="tab"
                href="#reactions"
              >
                                Reactions
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div className="tab-content">
            <div className="tab-pane fade show active" id="analytics">
              <LevelsAnalytics/>
            </div>
            <div className="tab-pane fade" id="reactions">
              <LevelsReactions/>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Divisions;

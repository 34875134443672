import React, {Component} from 'react';
import Layout from '../../layouts/layout';
import $ from 'jquery';
import moment from 'moment';
import ReportService from '../../../services/page_session/report-service';
import Spinner from '../../utils/spinner';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../../../assets/table.css';
import matchSorter from 'match-sorter';
import Pagination from '../../utils/table-pagination';
import Datepicker from '../../utils/datepicker';

class Report extends Component {
  constructor(props) {
    super(props);
    this.reportService = new ReportService();
    this.state = {
      loading: false,
      type: 'Teacher',
      users: [],
      users_filtered: [],
      generated_at: null,

      columns: [
        {
          Header: 'Full Name',
          id: 'fullName',
          show: true,
          accessor: (d) => (
            <button
              onClick={() => this.reportDetails(d.user.id)}
              className="btn btn-link p-0"
            >
              {d.user.fullName}
            </button>
          ),
        },

        {
          Header: 'Page Views',
          id: 'pageviews',
          show: true,
          accessor: (d) => Number(d.analytics['ga:pageviews']),
        },
        {
          Header: 'Page Views Per Session',
          id: 'pageviewsPerSession',
          show: true,
          accessor: (d) =>
            Math.round(d.analytics['ga:pageviewsPerSession']),
        },
        {
          Header: 'Avg Time On Page',
          id: 'avgTimeOnPage',
          show: true,
          accessor: (d) =>
            moment
                .utc(d.analytics['ga:avgTimeOnPage'] * 1000)
                .format('HH:mm:ss'),
        },
        {
          Header: 'Sessions',
          id: 'sessions',
          show: true,
          accessor: (d) => Number(d.analytics['ga:sessions']),
        },
        {
          Header: 'Avg Session Duration',
          id: 'avgSessionDuration',
          show: true,
          accessor: (d) =>
            moment
                .utc(d.analytics['ga:avgSessionDuration'] * 1000)
                .format('HH:mm:ss'),
        },
      ],
    };
  }

  componentDidMount() {
    const columns = this.state.columns.map((column) => {
      const value = localStorage.getItem(column.id);
      if (value) {
        column.show = JSON.parse(value);
      }
      return column;
    });

    this.setState({
      columns: columns,
    });

    $(document).on('click', '.dropdown-menu', function(e) {
      e.stopPropagation();
    });
  }

    dateChange = (start, end) => {
      this.setState({
        start_date: start,
        end_date: end,
      }, ()=> this.getReportData());
    };

    getReportData(force_update = 0) {
      this.setState({loading: true});
      this.reportService
          .users(
              this.state.start_date.format('YYYY-MM-DD'),
              this.state.end_date.format('YYYY-MM-DD'),
              this.state.type.toLowerCase(),
          )
          .then((data) => {
            this.setState({
              loading: false,
              users: data.users,
              users_filtered: data.users,
              generated_at: moment(data.generated_at).fromNow(),
            });
          });
    }

    reportDetails(user_id) {
      this.props.history.push('/page-session/report/' + user_id);
    }

    tabChange = (value) => {
      this.setState(
          {
            type: value,
          },
          () => {
            this.getReportData();
          },
      );
    };

    searchTable = (event) => {
      this.setState({
        users_filtered: matchSorter(this.state.users, event.target.value, {keys: ['user.fullName']}),
      });
    }

    toggleColumn = (event) => {
      const column_id = event.target.value;
      const columns = this.state.columns.map((column) => {
        if (column.id === column_id) {
          column.show = !column.show;
          localStorage.setItem(column_id, column.show);
        }
        return column;
      });

      this.setState({
        columns: columns,
      });
    }

    render() {
      const columns = this.state.columns;
      const users_filtered = this.state.users_filtered;
      return (
        <Layout title="Report">
          {this.state.loading && <Spinner/>}
          <div className="card-header px-0 py-2 bg-white border-0">
            <ul className="nav nav-tabs" id="tab" role="tablist">
              <li className="nav-item">
                <button
                  className={this.state.type === 'Teacher' ? 'btn-link nav-link active' : 'btn-link nav-link'}
                  onClick={() => this.tabChange('Teacher')}
                >
                                Teachers
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={this.state.type === 'Pupil' ? 'btn-link nav-link active' : 'btn-link nav-link'}
                  onClick={() => this.tabChange('Pupil')}
                  disabled
                >
                                Students
                </button>
              </li>
            </ul>
          </div>
          <div className="card-body">
            <div className="mb-2">
              <h2 className="title pull-left m-0">{this.state.type === 'Teacher' ? 'Teachers' : 'Students'}</h2>

              <Datepicker
                change={this.dateChange}
                options={{
                  disabled: true,
                }}
              />
              <div className="clearfix"/>
              <p className="text-black-50">
                            report generated {this.state.generated_at}{' '}
              </p>
            </div>
            <div className="input-group py-2 border border-gray">
              <div className="input-group-prepend">
                <span className="input-group-text bg-transparent border-0"><i
                  className="la la-search"/></span>
              </div>
              <input type="text" className="form-control border-0" placeholder="Search"
                onChange={this.searchTable}/>
              <div className="input-group-append">

                <div className="dropdown show">
                  <button className="btn btn-link border-0 dropdown-toggle" id="dropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Manage Columns
                  </button>
                  <div className="dropdown-menu rounded-0 shadow" aria-labelledby="dropdownMenuLink">
                    {
                      columns.map((c) =>
                        <label key={c.id} className="dropdown-item"
                          htmlFor={c.id}>
                          <input
                            id={c.id} type="checkbox"
                            value={c.id}
                            checked={c.show}
                            onChange={this.toggleColumn}/> {c.Header}
                        </label>,
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            <ReactTable
              data={users_filtered}
              columns={columns}
              PaginationComponent={Pagination}
              defaultPageSize={10}
              className=" -highlight"
            />
          </div>
        </Layout>
      );
    }
}

export default Report;

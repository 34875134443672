import React, {Component} from 'react';
import Spinner from '../../utils/spinner';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
  }

  render() {
    return (
      <div>
        {this.state.loading && <Spinner/>}
      </div>

    );
  }
}

export default Details;

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import AuthService from '../../services/auth-service';
import $ from 'jquery';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.authService = new AuthService();
  }

  componentDidMount() {
    let activeUrl = window.location.pathname.split('/');
    activeUrl = activeUrl[activeUrl.length - 2] + '/' + activeUrl[activeUrl.length - 1];
    $('a[href$="' + activeUrl + '"]').parent('li').addClass('active').parents('ul').addClass('show');
    $('li.menu-dropdown button').on('click', function(event) {
      $(this).next('ul').toggleClass('show');
    });
  }

    logout = () => {
      this.authService.logout();
    }

    render() {
      const props = this.props;
      // const authenticated = this.authService.isAuthenticated();
      return (
        <div>
          <nav className="navbar navbar-light bg-white shadow-sm">
            <Link to="/" className="navbar-brand">
              <img src="/assets/logo.svg" alt=""/>
            </Link>
            <div className="profile pull-right">

              <div className="dropdown dropleft show">
                <a href="/" role="button"
                  data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <img
                    alt="user"
                    className="avatar"
                    src="/assets/avatar.jpg"
                  />
                </a>
                <div className="dropdown-menu mt-2 rounded-0">
                  <button className="dropdown-item btn btn-link" onClick={this.logout}>Logout
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <div className="container-fluid main">
            <div className="row no-gutters">
              <div className="col-md-2">
                <div className="menu">
                  <ul>
                    <li>
                      <Link className="btn btn-link" to="/" title="Home">
                                            Dashboard
                      </Link>
                    </li>
                    <li className="menu-dropdown">
                      <button className="btn btn-link" type="button"
                      >
                                            Video analytics <i className="la la-angle-down"/>
                      </button>
                      <ul className="list-unstyled dropdown-menu"
                      >
                        <li>
                          <Link to="/video-analytics" title="Home">
                            <span>Home</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/video-analytics/top" title="Top">
                            <span>Top</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/video-analytics/teachers" title="Teachers">
                            <span>Teachers</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/video-analytics/divisions" title="Divisions">
                            <span>Divisions</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/video-analytics/subjects" title="Subjects">
                            <span>Subjects</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/video-analytics/chapters" title="Chapters">
                            <span>Chapters</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/video-analytics/videos" title="Videos">
                            <span>Videos</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-dropdown">
                      <button className="btn btn-link" type="button"
                      >
                                            Session analytics <i className="la la-angle-down"/>
                      </button>
                      <ul className="list-unstyled dropdown-menu"
                      >
                        <li>
                          <Link to="/page-session" title="Home">
                            <span>Home</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/page-session/report" title="Users">
                            <span>Users report</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/page-session/top-users" title="Top users">
                            <span>Top users</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-dropdown">
                      <button className="btn btn-link" type="button"
                      >
                                            Regional analytics <i className="la la-angle-down"/>
                      </button>
                      <ul className="list-unstyled dropdown-menu"
                      >
                        <li>
                          <Link to="/regional" title="Home">
                            <span>Home</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/regional/map" title="Users map">
                            <span>Users map</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-dropdown">
                      <button className="btn btn-link" type="button"
                      >
                                            Subscription analytics <i className="la la-angle-down"/>
                      </button>
                      <ul className="list-unstyled dropdown-menu"
                      >
                        <li>
                          <Link to="/subscriptions" title="Home">
                            <span>Home</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/subscriptions/offers" title="Offers">
                            <span>Offers</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/subscriptions/students" title="Students">
                            <span>Students</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/video-analytics/free_offer_durations" title="Videos">
                            <span>Trial/Trial+ Offer</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-dropdown">
                      <button className="btn btn-link" type="button"
                      >
                                            Live sessions analytics <i className="la la-angle-down"/>
                      </button>
                      <ul className="list-unstyled dropdown-menu"
                      >
                        <li>
                          <Link to="/live-session" title="Home">
                            <span>Home</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/user-sessions-history" title="Home">
                            <span>User History</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>

                </div>
              </div>
              <div className="col-md-10">
                <div className="content shadow">
                  {/* <div className="heading">
                                    <div className="title pull-left">
                                        <h1>{props.title}</h1>
                                    </div>
                                </div>*/}
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default Layout;

import React, {Component} from 'react';

class UnderConstruction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="under-construction"><i className="d-block la la-cog la-3x la-spin"/>Under Construction</div>
    );
  }
}

export default UnderConstruction;

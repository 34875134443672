import React, {Component} from 'react';
import Layout from '../../layouts/layout';
import TopTeachers from './teachers';
import TopLevels from './levels';
import TopSubjects from './subjects';
import TopChapters from './chapters';
import TopVideos from './videos';
import UnderConstruction from '../../utils/under-construction';

class Tops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <Layout title="Tops">
        <UnderConstruction/>
        <div className="card-header px-0 py-2 bg-white border-0">
          <ul className="nav nav-tabs" id="tab">
            <li className="nav-item">
              <a
                className="btn-link nav-link active"
                id="general-tab"
                data-toggle="tab"
                href="#teachers"
              >
                                Teachers
              </a>
            </li>
            <li className="nav-item">
              <a
                className="btn-link nav-link"
                id="reactions-tab"
                data-toggle="tab"
                href="#levels"
              >
                                Levels
              </a>
            </li>
            <li className="nav-item">
              <a
                className="btn-link nav-link"
                id="reactions-tab"
                data-toggle="tab"
                href="#subjects"
              >
                                Subjects
              </a>
            </li>
            <li className="nav-item">
              <a
                className="btn-link nav-link"
                id="reactions-tab"
                data-toggle="tab"
                href="#chapters"
              >
                                Chapters
              </a>
            </li>
            <li className="nav-item">
              <a
                className="btn-link nav-link"
                id="reactions-tab"
                data-toggle="tab"
                href="#videos"
              >
                                Videos
              </a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          {/* <div className="tab-content">*/}
          {/*  <div className="tab-pane fade show active" id="teachers">*/}
          {/*    <TopTeachers/>*/}
          {/*  </div>*/}
          {/*  <div className="tab-pane fade" id="levels">*/}
          {/*    <TopLevels/>*/}
          {/*  </div>*/}
          {/*  <div className="tab-pane fade" id="subjects">*/}
          {/*    <TopSubjects/>*/}
          {/*  </div>*/}
          {/*  <div className="tab-pane fade" id="chapters">*/}
          {/*    <TopChapters/>*/}
          {/*  </div>*/}
          {/*  <div className="tab-pane fade" id="videos">*/}
          {/*    <TopVideos/>*/}
          {/*  </div>*/}
          {/* </div>*/}
        </div>
      </Layout>
    );
  }
}

export default Tops;

import React, {Component} from 'react';
import Spinner from '../../utils/spinner';
import ReactTable from 'react-table';
import Pagination from '../../utils/table-pagination';
import Modal from 'react-modal';
import Details from '../subjects/details';
import HomeService from '../../../services/live_session/home';
import ListingService from '../../../services/common/listing';
import {getUsers} from '../../../services/free_offer_duration/freeOfferService';
import $ from 'jquery';
import moment from 'moment';
import Datepicker from '../../utils/datepicker';


class FreeOfferDurations extends Component {
  constructor(props) {
    super(props);
    this.homeService = new HomeService();
    this.listingService = new ListingService();

    this.state = {
      data_loading: false,
      filter_loading: false,
      analytics: [],
      analytics_filtered: [],
      teacher_options: [],
      selected_teacher_option: null,
      page: 0,
      userId: null,
     // sortOptions: [ { id: "free_duration", desc: true }],
      columns: [
        {
          Header: 'User Id',
          id: 'user_id',
          show: true,
          accessor: (d) => d.user_id,
        },
        {
          Header: 'Full Name',
          id: 'full_name',
          show: true,
          accessor: (d) => d.full_name
        },
        {
          Header: 'Phone',
          id: 'phone',
          show: true,
          accessor: (d) => d.phone
        },
        {
          Header: 'Remain Duration',
          id: 'free_duration',
          show: true,
          accessor: (d) => d.free_duration,
          Cell: (row) => <span
          className={row.value ?
              `badge badge-info` :
              `badge badge-warning`}>
          {moment.duration(row.value, 'seconds').format('y [years], w [weeks], d [days], h [hours], m [minutes], s [seconds]')}</span>,
        
        },
        {
          Header: 'Offer',
          id: 'offer_name',
          show: true,
          accessor: (d) => d.offer_name
        },
        {
          Header: 'Division',
          id: 'division',
          show: true,
          accessor: (d) => d.division
        },
        {
          Header: 'Start Date',
          id: 'start_date',
          show: true,
          accessor: (d) => {return moment(d.start_date)
          .local()
          .format("DD-MM-YYYY HH:mm")}
        },
        {
          Header: 'End Date',
          id: 'end_date',
          show: true,
          accessor: (d) => d.due_date,
          Cell: (row) => <span
              className={moment()>= moment(row.value) ?
                  `badge badge-danger` :
                  `badge badge-success`}>
          {moment(row.value)
          .local()
          .format("DD-MM-YYYY HH:mm")}</span>
        },
        // ]
        // },
      //  this.state.start_date.toISOString(),

      ],
    };
  }
  dateChange = (start, end, getData = true) => {
    this.setState({
      start_date: start,
      end_date: end,
    }, () => {
      if (getData) {
        this.getData();
      }
    });
  };
  getData = (state) => {
    this.setState({data_loading: true});
    getUsers(
        state ? state.page : 0,
        this.state.userId,
    ).then((data) => {
      this.setState({
        data_loading: false,
        analytics: data.users,
        analytics_filtered: data.users,
        pages: data.pages,
      });
    });
  };

  componentDidMount() {
    const columns = this.state.columns.map((column) => {
      const value = localStorage.getItem(column.id);
      if (value) {
       // column.show = JSON.parse(value);
      }
      return column;
    });

    this.setState({
      columns: columns,
    });

    $(document).on('click', '.dropdown-menu', function(e) {
      e.stopPropagation();
    });
  }

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({
      'userId': value || null,
    });
    if (!value.length) {
      console.log('kkkk');
    }
  };
  toggleColumn = (event) => {
    const column_id = event.target.value;
    const columns = this.state.columns.map((column) => {
      if (column.id === column_id) {
        column.show = !column.show;
        localStorage.setItem(column_id, column.show);
      }
      if (column.columns) {
        column.columns.map((child) => {
          if (child.id === column_id) {
            child.show = !child.show;
            localStorage.setItem(column_id, child.show);
          }
        });
      }
      return column;
    });

    this.setState({
      columns: columns,
    });
  };
  render() {
    const columns = this.state.columns;
    const analytics_filtered = this.state.analytics_filtered;

    return (
        <div>
          {this.state.loading && <Spinner/>}
          <div className="mb-2">
            <h2 className="title pull-left m-0">Analytics</h2>
            {/* <Datepicker change={this.dateChange}/> */}
            <div className="clearfix"/>
            <p className="text-black-50">report
              generated {this.state.generated_at}</p>
          </div>
          <div className="input-group py-2 border border-gray">
            <div className="input-group-prepend">
            </div>
            <input type="text" className="form-control"
                   placeholder="Search (user id )"
                   onChange={this.handleChange}/>
            <span className="input-group-text bg-transparent border-0"><i
                className="la la-search" style={{'cursor': 'pointer'}}
                onClick={this.getData}/></span>
            <div className="input-group-append">

              <div className="dropdown show">
                <button className="btn btn-link border-0 dropdown-toggle"
                        id="dropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">Manage
                  Columns
                </button>
                <div className="dropdown-menu rounded-0 shadow"
                     aria-labelledby="dropdownMenuLink">
                  {
                    columns.map((c) =>
                        <label key={c.id} className="dropdown-item"
                               htmlFor={c.id}>
                          <input
                              id={c.id} type="checkbox"
                              value={c.id}
                              checked={c.show}
                              onChange={this.toggleColumn}/> {c.Header}
                        </label>,
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <ReactTable
              data={analytics_filtered}
              columns={columns}
              PaginationComponent={Pagination}
              className=" -highlight"
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.getData}
              // //sorted={this.state.sortOptions}
              // sortable={true}
              //manual
              defaultPageSize={100}
          />
          <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              contentLabel="Subject Details"
              ariaHideApp={false}
          >
            <Details/>
            <button onClick={this.closeModal}
                    className="btn btn-link float-right">
              <i className="la la-close"/>
            </button>

          </Modal>
        </div>

    );
  }
}

export default FreeOfferDurations;


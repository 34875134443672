import React, {Component} from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {PrivateRoute} from './services/route-guard';
import Login from './components/auth/login';
import Home from './components/video_analytics/home/home';
import Top from './components/video_analytics/top/top';
import Teachers from './components/video_analytics/teachers/teachers';
import Map from './components/regional/map';
import Divisions from './components/video_analytics/divisions/divisions';
import Subjects from './components/video_analytics/subjects/subjects';
import Videos from './components/video_analytics/videos/videos';
import Chapters from './components/video_analytics/chapters/chapters';
import NotFound from './components/error/not-found';

import HomeTracking from './components/page_session/home';
import Report from './components/page_session/report/report';
import ReportDetail from './components/page_session/report/report-detail';
import TopUsers from './components/page_session/top-users';

import HomeLiveSession from './components/live_session/home';

import HomeRegion from './components/regional/home';

import Dashboard from './components/dashboard';

import SubscriptionHome from './components/subscription/home';
import Offers from './components/subscription/offer/list';
import Students from './components/subscription/student/list';
import trailOffer
  from './components/video_analytics/videos/trailOffer';
import UserSessionHistory from './components/user/UserSessionHistory';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login}/>
          <PrivateRoute exact path="/" component={Dashboard}/>

          <PrivateRoute exact path="/video-analytics/" component={Home}/>
          <PrivateRoute exact path="/video-analytics/top" component={Top}/>
          <PrivateRoute exact path="/video-analytics/teachers" component={Teachers}/>
          <PrivateRoute exact path="/video-analytics/divisions" component={Divisions}/>
          <PrivateRoute exact path="/video-analytics/subjects" component={Subjects}/>
          <PrivateRoute exact path="/video-analytics/chapters" component={Chapters}/>
          <PrivateRoute exact path="/video-analytics/videos" component={Videos}/>
          <PrivateRoute exact path="/video-analytics/free_offer_durations" component={trailOffer}/>
          <PrivateRoute exact path="/regional" component={HomeRegion}/>
          <PrivateRoute exact path="/regional/map" component={Map}/>

          <PrivateRoute exact path="/subscriptions" component={SubscriptionHome}/>
          <PrivateRoute exact path="/subscriptions/offers" component={Offers}/>
          <PrivateRoute exact path="/subscriptions/students" component={Students}/>

          <PrivateRoute exact path="/page-session" component={HomeTracking}/>
          <PrivateRoute exact path="/page-session/report" component={Report}/>
          <PrivateRoute exact path="/page-session/report/:user_id" component={ReportDetail}/>
          <PrivateRoute exact path="/page-session/top-users" component={TopUsers}/>

          <PrivateRoute exact path="/live-session" component={HomeLiveSession}/>
          <PrivateRoute exact path="/user-sessions-history" component={UserSessionHistory}/>


          <PrivateRoute exact path="**" component={NotFound}/>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;

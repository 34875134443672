import React, {Component} from 'react';
import Layout from './layouts/layout';
import Spinner from './utils/spinner';
import UnderConstruction from './utils/under-construction';
import Datepicker from './utils/datepicker';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {

  }

  dateChange = (startDate, endDate) => {
  }

  render() {
    return (
      <Layout title="Dashboard">
        {this.state.loading && <Spinner/>}
        <div className="card-header px-0 py-2 bg-white border-0">

        </div>
        <div className="card-body">
          <div className="mb-2">
            <h2 className="title pull-left m-0">Dashboard</h2>
            <Datepicker
              change={this.dateChange}
            />
            <div className="clearfix"/>
            <p className="text-black-50">report generated just now</p>
          </div>
          <UnderConstruction/>
        </div>
      </Layout>
    );
  }
}

export default Dashboard;

import React, {Component} from 'react';
import $ from 'jquery';
import moment from 'moment';
import 'bootstrap-daterangepicker';
import PropTypes from 'prop-types';

class Datepicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      selector: Math.random().toString().substr(2, 5) + '-datepicker',
    };
  };

  componentDidMount() {
    const sessions = {
      'This session': [this.currentSessionStart(), this.currentSessionEnd()],
      'Last session': [
        this.currentSessionStart().subtract(1, 'years'),
        this.currentSessionEnd().subtract(1, 'years'),
      ],
    };
    let start = moment(sessionStorage.getItem('startDate'));
    let end = moment(sessionStorage.getItem('endDate'));
    if (!start.isValid() && !end.isValid()) {
      start = sessions['This session'][0];
      end = sessions['This session'][1];
    }

    if (this.props.options.disabled) {
      start = moment().startOf('month');
      end = moment().endOf('month');
    }

    this.dateChange(start, end);
    $('.' + this.state.selector).daterangepicker(
      {
        startDate: start,
        endDate: end,
        autoApply: true,
        opens: 'left',
        locale: {
          'customRangeLabel': 'Custom',
        },
        ranges: {
          ...sessions,
          'Last 7 days': [moment().subtract(7, 'days'), moment()],
          'Last 28 days': [moment().subtract(28, 'days'), moment()],
          'Last 90 days': [moment().subtract(90, 'days'), moment()],
          'Last 365 days': [moment().subtract(365, 'days'), moment()],
          'Lifetime': [moment().year(2015), moment()],

          [' ' + moment().year()]: [
            moment().startOf('year'),
            moment().endOf('year')],
          [' ' + moment().subtract(1, 'year').year()]: [
            moment().subtract(1, 'year').startOf('year'),
            moment().subtract(1, 'year').endOf('year'),
          ],

          [moment().format('MMMM')]: [
            moment().startOf('month'),
            moment().endOf('month')],
          [moment().subtract(1, 'month').format('MMMM')]: [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
          [moment().subtract(2, 'month').format('MMMM')]: [
            moment().subtract(2, 'month').startOf('month'),
            moment().subtract(2, 'month').endOf('month'),
          ],

        },
      },
      this.dateChange,
    ).on('show.daterangepicker', function() {
      $('.daterangepicker.show-calendar .ranges').show();
      if ($('.daterangepicker').hasClass('show-calendar')) {
        $('.daterangepicker').removeClass('show-calendar');
        $('.daterangepicker.show-calendar .ranges').show();
      }
    }).on('showCalendar.daterangepicker', function() {
      $('.daterangepicker.show-calendar .ranges').hide();
    });
  }

  dateChange = (start, end) => {
    $('.' + this.state.selector + ' span').html(
      start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'),
    );
    this.setState({
      startDate: start,
      endDate: end,
    });
    sessionStorage.setItem('startDate', start.format());
    sessionStorage.setItem('endDate', end.format());
    this.props.change(start, end);
  };

  currentSessionStart() {
    const input = moment();
    const output = input.clone().startOf('month').month(6);
    return output < input ? output : output.subtract(1, 'years');
  }

  currentSessionEnd() {
    const input = moment();
    const output = input.clone().endOf('month').month(5);
    return output > input ? output : output.add(1, 'years');
  }

  render() {
    return (
      <div className="filter pull-right">
        <div
          className={this.state.selector}
          style={{
            background: '#fff',
            cursor: 'pointer',
            padding: '5px 10px',
            border: '1px solid #ccc',
            width: '100%',
            pointerEvents: this.props.options.disabled ? 'none' : '',
          }}
        >
          <i className="fa fa-calendar"/>&nbsp;
          <span/> <i className="fa fa-caret-down"/>
        </div>
      </div>
    );
  }
}

Datepicker.propTypes = {
  change: PropTypes.func.isRequired,
  options: PropTypes.shape({
    disabled: PropTypes.bool,
  }),
};

Datepicker.defaultProps = {
  options: {
    disabled: false,
  },
};

export default Datepicker;

import React, {Component} from 'react';
import Layout from '../layouts/layout';

class TopUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <Layout title="Not found">
        <div className="card-header px-0 py-2 bg-white border-0">
        </div>
        <div className="card-body">

          <div className="mb-2">
            <h2 className="title pull-left m-0">Page does not exist</h2>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TopUsers;

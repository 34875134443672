class ChaptersService {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  async analytics(startDate, endDate, divisionId, subjectId, page) {
    return fetch(`${this.apiUrl}/video-analytics/chapters/progress-analytics/?startDate=${startDate}&endDate=${endDate}&divisionId=${divisionId}&subjectId=${subjectId}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async reactions(startDate, endDate, divisionId, subjectId, page) {
    return fetch(
        `${this.apiUrl}/video-analytics/chapters/reaction-analytics/?startDate=${startDate}&endDate=${endDate}&divisionId=${divisionId}&subjectId=${subjectId}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async chapter(chapterId, startDate, endDate) {
    return fetch(
        `${this.apiUrl}/video-analytics/chapters/${chapterId}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }
}

export default ChaptersService;

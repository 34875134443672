import React, {Component} from 'react';
import Layout from '../../layouts/layout';
import Spinner from '../../utils/spinner';
import freeOfferDurations from './FreeOfferDurations';
import SubjectsAnalytics from '../subjects/analytics';
import FreeOfferDurations from './FreeOfferDurations';

class trialOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
        <Layout title="Subjects">
          {this.state.loading && <Spinner/>}
          <div className="card-header px-0 py-2 bg-white border-0">
            <ul className="nav nav-tabs" id="tab">
              <li className="nav-item">
                <a
                    className="btn-link nav-link active"
                    id="general-tab"
                    data-toggle="tab"
                    href="#analytics"
                >
                  Trial & Trial +
                </a>
              </li>
            </ul>
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="analytics">
                <FreeOfferDurations/>
              </div>
            </div>
          </div>
        </Layout>
    );
  }
}

export default trialOffer;

class VideosService {
  constructor() {
    this.api_url = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  async analytics(
      startDate,
      endDate,
      teacherId,
      divisionId,
      subjectId,
      chapterId,
      page) {
    return fetch(
        `${this.api_url}/video-analytics/videos/progress-analytics/?startDate=${startDate}&endDate=${endDate}&teacherId=${teacherId}&divisionId=${divisionId}&subjectId=${subjectId}&chapterId=${chapterId}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async reactions(
      startDate,
      endDate,
      teacherId,
      divisionId,
      subjectId,
      chapterId,
      page) {
    return fetch(
        `${this.api_url}/video-analytics/videos/reaction-analytics/?startDate=${startDate}&endDate=${endDate}&teacherId=${teacherId}&divisionId=${divisionId}&subjectId=${subjectId}&chapterId=${chapterId}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async video(videoId, chapterId, startDate, endDate) {
    return fetch(
        `${this.api_url}/video-analytics/videos/${videoId}/chapter/${chapterId}/?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }
}

export default VideosService;

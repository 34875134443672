class AuthService {
    api_url = process.env.REACT_APP_API_URL;
    token = localStorage.getItem("token");

    // constructor() {}

    async login(user) {
        return fetch(`${this.api_url}/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        }).then(async response => {
            if (response.ok) {
                const json = await response.json();
                localStorage.setItem("token", json.token);
                window.location.href = "/";
            } else {
                return "Login failed";
            }
        });
    }

    logout() {
        localStorage.removeItem("token");
        window.location.href = "/login";
    }

    isAuthenticated() {
        return localStorage.getItem("token") != null;
    }

    async user() {
        return fetch(`${this.api_url}/auth/user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token
            }
        }).then(response => {
            return response.json();
        });
    }
}

export default AuthService;

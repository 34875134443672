import React, {Component} from 'react';
import Layout from '../../layouts/layout';
import Spinner from '../../utils/spinner';
import $ from 'jquery';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../../../assets/table.css';
import matchSorter from 'match-sorter';
import Pagination from '../../utils/table-pagination';
import ListingService from '../../../services/common/listing';
import Select from 'react-select';
import Details from './details';
import Modal from 'react-modal';
import UnderConstruction from '../../utils/under-construction';
import Datepicker from '../../utils/datepicker';

class List extends Component {
  constructor(props) {
    super(props);
    this.listingService = new ListingService();
    this.state = {
      loading: false,
      analytics: [],
      analytics_filtered: [],
      teacher_options: [],
      selected_teacher_option: null,
      level_options: [],
      selected_level_option: null,
      subject_options: [],
      selected_subject_option: null,
      chapter_options: [],
      selected_chapter_option: null,
      pages: 0,

      columns: [
        {
          Header: 'Name',
          id: 'name',
          show: true,
          accessor: (d) => (
            <button
              onClick={() => this.openModal(d.id)}
              className="btn btn-link p-0"
            >
              {d.name}
            </button>
          ),
        },

        {
          Header: 'Buyers',
          id: 'buyers',
          show: true,
          accessor: (d) => d.analytics.buyers,
        },

        {
          Header: 'Earnings',
          id: 'earnings',
          show: true,
          accessor: (d) => d.analytics.earnings,
        },
      ],
    };
  }

  componentDidMount() {
    this.getTeacherFilterData();
    this.getLevelFilterData();
    const columns = this.state.columns.map((column) => {
      const value = localStorage.getItem(column.id);
      if (value) {
        column.show = JSON.parse(value);
      }
      return column;
    });

    this.setState({
      columns: columns,
    });

    $(document).on('click', '.dropdown-menu', function(e) {
      e.stopPropagation();
    });
  }

  dateChange = (start, end) => {
    this.setState({
      start_date: start,
      end_date: end,
    }, ()=> this.getData());
  };

  getData = (state) => {
    const teacherId = this.state.selected_teacher_option && this.state.selected_teacher_option.value;
    const divisionId = this.state.selected_level_option && this.state.selected_level_option.value;
    const subjectId = this.state.selected_subject_option && this.state.selected_subject_option.value;
    const chapterId = this.state.selected_chapter_option && this.state.selected_chapter_option.value;
    if (divisionId && subjectId && chapterId) {
      this.setState({loading: true});
      // this.videosService
      //   .analytics(
      //     this.state.start_date.toISOString(),
      //     this.state.end_date.toISOString(),
      //     teacherId,
      //     divisionId,
      //     subjectId,
      //     chapterId,
      //     state ? state.page : 0
      //   )
      //   .then(data => {
      //     this.setState({
      //       loading: false,
      //       analytics: data.data,
      //       analytics_filtered: data.data,
      //       pages: data.pages
      //     });
      //   });
    }
  }

  getTeacherFilterData() {
    this.setState({loading: true});
    this.listingService
        .teachers()
        .then((data) => {
          this.setState({
            loading: false,
            teacher_options: data,
          });
        });
  }

  getLevelFilterData() {
    this.setState({loading: true});
    this.listingService
        .divisions()
        .then((data) => {
          this.setState({
            loading: false,
            level_options: data,
          });
        });
  }

  getSubjectFilterData(level_id) {
    this.setState({loading: true});
    this.listingService
        .levelSubjects(level_id)
        .then((data) => {
          this.setState({
            loading: false,
            subject_options: data,
            selected_subject_option: null,
          });
        });
  }

  getChapterFilterData(subject_id) {
    this.setState({loading: true});
    this.listingService
        .subjectChapters(subject_id)
        .then((data) => {
          this.setState({
            loading: false,
            chapter_options: data,
            selected_chapter_option: null,
          });
        });
  }

  searchTable = (event) => {
    this.setState({
      analytics_filtered: matchSorter(this.state.analytics, event.target.value, {keys: ['name']}),
    });
  }

  teacherFilterChange = (selectedOption) => {
    this.setState(
        {selected_teacher_option: selectedOption},
        () => this.getData(),
    );
  }

  levelFilterChange = (selectedOption) => {
    this.setState(
        {selected_level_option: selectedOption},
        () => this.getSubjectFilterData(selectedOption.value),
    );
  }

  subjectFilterChange = (selectedOption) => {
    this.setState(
        {selected_subject_option: selectedOption},
        () => this.getChapterFilterData(selectedOption.value),
    );
  }

  chapterFilterChange = (selectedOption) => {
    this.setState(
        {selected_chapter_option: selectedOption},
        () => this.getData(),
    );
  }

  toggleColumn = (event) => {
    const column_id = event.target.value;
    const columns = this.state.columns.map((column) => {
      if (column.id === column_id) {
        column.show = !column.show;
        localStorage.setItem(column_id, column.show);
      }
      return column;
    });

    this.setState({
      columns: columns,
    });
  }

  openModal = (id) => {
    this.setState({
      selected: id,
      modalIsOpen: true,
    });
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  render() {
    const columns = this.state.columns;
    const analytics_filtered = this.state.analytics_filtered;
    const selected_teacher_option = this.state.selected_teacher_option;
    const teacher_options = this.state.teacher_options;
    const selected_level_option = this.state.selected_level_option;
    const level_options = this.state.level_options;
    const selected_subject_option = this.state.selected_subject_option;
    const subject_options = this.state.subject_options;
    const selected_chapter_option = this.state.selected_chapter_option;
    const chapter_options = this.state.chapter_options;

    return (
      <Layout title="Dashboard">
        <UnderConstruction/>
        {/* this.state.loading && <Spinner/>*/}
        <div className="card-header px-0 py-2 bg-white border-0">

        </div>
        <div className="card-body">
          <div className="mb-2">
            <h2 className="title pull-left m-0">Analytics</h2>
            <Datepicker change={this.dateChange}/>
            <div className="clearfix"/>
            <p className="text-black-50">report generated {this.state.generated_at}</p>
          </div>
          <div className="border p-2 mb-3">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Teacher (optional)</label>
                  <Select
                    value={selected_teacher_option}
                    onChange={this.teacherFilterChange}
                    options={teacher_options}
                    placeholder="Search teachers"
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Level</label>
                  <Select
                    value={selected_level_option}
                    onChange={this.levelFilterChange}
                    options={level_options}
                    placeholder="Search levels"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Subject</label>
                  <Select
                    value={selected_subject_option}
                    onChange={this.subjectFilterChange}
                    options={subject_options}
                    placeholder="Search subjects"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Chapter</label>
                  <Select
                    value={selected_chapter_option}
                    onChange={this.chapterFilterChange}
                    options={chapter_options}
                    placeholder="Search chapters"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="input-group py-2 border border-gray">
            <div className="input-group-prepend">
              <span className="input-group-text bg-transparent border-0"><i
                className="la la-search"/></span>
            </div>
            <input type="text" className="form-control border-0" placeholder="Search"
              onChange={this.searchTable}/>
            <div className="input-group-append">

              <div className="dropdown show">
                <button className="btn btn-link border-0 dropdown-toggle" id="dropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Manage
                  Columns
                </button>
                <div className="dropdown-menu dropdown-menu-left rounded-0 shadow"
                  aria-labelledby="dropdownMenuLink">
                  {
                    columns.map((c) =>
                      <label key={c.id} className="dropdown-item"
                        htmlFor={c.id}>
                        <input
                          id={c.id} type="checkbox"
                          value={c.id}
                          checked={c.show}
                          onChange={this.toggleColumn}/> {c.Header}
                      </label>,
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <ReactTable
            data={analytics_filtered}
            columns={columns}
            PaginationComponent={Pagination}
            defaultPageSize={10}
            className=" -highlight"

            manual
            pages={this.state.pages}
            loading={this.state.loading}
            onFetchData={this.getData}
            sortable={false}
          />
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            contentLabel="Teacher Details"
            ariaHideApp={false}
          >
            <Details/>
            <button onClick={this.closeModal} className="btn btn-link float-right">
              <i className="la la-close"/>
            </button>
          </Modal>
        </div>
      </Layout>
    );
  }
}

export default List;

import React, {Component} from 'react';
import Spinner from '../utils/spinner';
import moment from 'moment';
import ReactTable from 'react-table';

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: 0,

      columns: [
        {
            Header: 'Name',
            id: 'name',
            show: true,
            accessor: (d) => d.content.name,
          },
          {
            Header: 'Instructor',
            id: 'instructor',
            show: true,
            accessor: (d) =>  <span key={d.id} className="badge badge-info ml-1">{d.content.instructor.fullName}</span>
          },
          {
            Header: 'Start Date',
            id: 'start_date',
            show: true,
            accessor: (d) => {return moment(d.start_date)
            .local()
            .format("DD-MM-YYYY HH:mm")}
          },
          {
            Header: 'End Date',
            id: 'end_date',
            show: true,
            accessor: (d) => {return moment(d.end_date)
            .local()
            .format("DD-MM-YYYY HH:mm")}
          },
      ],
    };
  }
  componentDidMount() {
    this.setState({
      loading: true,
    });
  }
 
  render() {
    const columns = this.state.columns;
    return (
      <div>
        <h1>User Events Histories</h1>
        {this.props.events.length > 0 ?
        (
          <ReactTable
            data={this.props.events}
            columns={columns}
            className=" -highlight"
        />
        ) : (
          <span>
            No events found
          </span>
        )
        }
      </div>

    );
  }
}

export default EventDetails;

class LevelsService {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  async states() {
    return fetch(`${this.apiUrl}/regional-analytics/states`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }

  async stateAnalytics(id) {
    return fetch(`${this.apiUrl}/regional-analytics/states/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }
}

export default LevelsService;

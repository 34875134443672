class HomeService {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  async overview(startDate, endDate, type) {
    return fetch(`${this.apiUrl}/session-analytics/home/${type}/?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }

  async chart(startDate, endDate, type) {
    return fetch(`${this.apiUrl}/session-analytics/home/chart/${type}/?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }
}

export default HomeService;

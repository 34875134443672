class DivisionsService {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  async analytics(startDate, endDate, page) {
    return fetch(
        `${this.apiUrl}/video-analytics/divisions/progress-analytics/?startDate=${startDate}&endDate=${endDate}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async reactions(startDate, endDate, page) {
    return fetch(
        `${this.apiUrl}/video-analytics/divisions/reaction-analytics/?startDate=${startDate}&endDate=${endDate}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async level(levelId, startDate, endDate) {
    return fetch(
        `${this.apiUrl}/video-analytics/divisions/${levelId}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }
}

export default DivisionsService;


  export const getUsers=( page , user_id) =>{
    return fetch(`${process.env.REACT_APP_API_URL}/free-offer-duration/users?page=${page}&user_id=${user_id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
    })
    .then((response) => {
      return response.json();
    });
  }
export default {getUsers};

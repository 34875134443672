import React, {Component} from 'react';
import Layout from '../layouts/layout';
import Spinner from '../utils/spinner';
import {RadioSVGMap} from 'react-svg-map';
import Tunisia from '../utils/tunisia';
import StateService from '../../services/geographical/state';
import Select from 'react-select';
import UnderConstruction from '../utils/under-construction';
import Datepicker from '../utils/datepicker';

class Map extends Component {
  constructor(props) {
    super(props);
    this.stateService = new StateService();
    this.state = {
      loading: false,
      selectedLocation: null,
      pointedLocation: null,
      tooltipStyle: {
        display: 'none',
      },
      state_analytics: {},
    };
  }

  componentDidMount() {
  }

  dateChange = (start, end) => {
    this.setState({
      start_date: start,
      end_date: end,
    }, ()=> this.getData);
  };

  getData() {
    this.setState({loading: true});
    let id = this.state.selectedLocation.id;
    if (this.state.selectedLocation.id === '6-1' || this.state.selectedLocation.id === '6-2') {
      id = id.charAt(0);
    }
    this.stateService
        .stateAnalytics(id)
        .then((data) => {
          this.setState({
            loading: false,
            state_analytics: data,
          });
        });
  }

  handleLocationMouseOver = (event) => {
    const pointedLocation = this.getLocationName(event);
    this.setState({pointedLocation});
  };

  getLocationName = (event) => {
    return event.target.attributes.name.value;
  };

  handleLocationMouseOut = () => {
    this.setState({pointedLocation: null, tooltipStyle: {display: 'none'}});
  };

  handleLocationMouseMove = (event) => {
    const tooltipStyle = {
      display: 'block',
      top: event.clientY + 10,
      left: event.clientX - 100,
    };
    this.setState({tooltipStyle});
  };

  handleChange = (event) => {
    this.setState({
      selectedLocation: {
        id: event.getAttribute('id'),
        name: event.getAttribute('name'),
      },
    }, this.getData);
  };

  render() {
    return (
      <Layout title="Students">
        <UnderConstruction/>
        {this.state.loading && <Spinner/>}
        <div className="card-header px-0 py-2 bg-white border-0">
        </div>
        <div className="card-body">
          <div className="mb-2">
            <h2 className="title pull-left m-0">Users Map</h2>
            <Datepicker change={this.dateChange}/>
            <div className="clearfix"/>
            <p className="text-black-50">report generated just now</p>
          </div>
          <div className="row">
            <div className="col-md-5">
              <RadioSVGMap
                map={Tunisia}
                onLocationMouseOver={this.handleLocationMouseOver}
                onLocationMouseOut={this.handleLocationMouseOut}
                onLocationMouseMove={this.handleLocationMouseMove}
                onChange={this.handleChange}
              />
              <div className="map__tooltip" style={this.state.tooltipStyle}>
                {this.state.pointedLocation}
              </div>
              <div className="float-right">
                <ul className="map-keys">
                  <li className="key first-key"><span/>No data</li>
                  <li className="key second-key"><span/>1-99 students</li>
                  <li className="key third-key"><span/>100-999 students</li>
                  <li className="key fourth-key"><span/>1000-10K students</li>
                  <li className="key fifth-key"><span/>10K+ students</li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <div className="border p-2 mb-3">
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <label>Delegation filter</label>
                      <Select
                        // value={selected_teacher_option}
                        // onChange={this.teacherFilterChange}
                        // options={teacher_options}
                        placeholder="Search delegations"
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <label>Institute filter</label>
                      <Select
                        // value={selected_teacher_option}
                        // onChange={this.teacherFilterChange}
                        // options={teacher_options}
                        placeholder="Search institutes"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p className="governorate-name">
                {!this.state.selectedLocation && 'Select a governorate'}
                {this.state.selectedLocation && this.state.selectedLocation.name}
              </p>
              <table className="table striped table-hover">
                <tbody>
                  <tr>
                    <th>Number of users</th>
                    <td>2458</td>
                  </tr>
                  <tr>
                    <th>Earnings</th>
                    <td>18752 Dt</td>
                  </tr>
                  <tr>
                    <th>Number of subscribed users</th>
                    <td>
                    Expired: <span className="badge badge-info mr-1">74</span>
                    Active: <span className="badge badge-info mr-1">14</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Subscriptions types</th>
                    <td>
                    Silver <span className="badge badge-info mr-1">22</span>
                    Milloul lilekher <span className="badge badge-danger mr-1">0</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Covered delegations</th>
                    <td>7 - 53% of 13</td>
                  </tr>
                  <tr>
                    <th>Covered institutes</th>
                    <td>3 - 20% of 15</td>
                  </tr>
                  <tr>
                    <th>Total watched time</th>
                    <td>0 years, 3 weeks, 0 days, 5 hours, 0 minutes, 0 seconds</td>
                  </tr>
                  <tr>
                    <th>Total views</th>
                    <td>47898</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Map;

import React, {Component} from 'react';

class Spinner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="loading">Loading&#8230;</div>
    );
  }
}

export default Spinner;

class ChaptersService {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  async analytics(startDate, endDate, nbSessions, offerId, page) {
    return fetch(`${this.apiUrl}/live-sessions/search-history?startDate=${startDate}&endDate=${endDate}&nbSessions=${nbSessions}&offre=${offerId}&sort=asc&sortName=start_time&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

    async export(startDate, endDate, nbSessions, offerId) {
        return fetch(`${this.apiUrl}/live-sessions/export-history?startDate=${startDate}&endDate=${endDate}&nbSessions=${nbSessions}&offre=${offerId}&sort=asc&sortName=start_time&rows=6000`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.token,
                },
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `students.csv`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }
}


export default ChaptersService;

import React, {Component} from 'react';
import $ from 'jquery';
import moment from 'moment';
import Spinner from '../utils/spinner';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../../assets/table.css';
import matchSorter from 'match-sorter';
import Pagination from '../utils/table-pagination';
import userHistoryService
  from '../../services/live_session/user-history-service';
import ListingService from '../../services/common/listing';
import Select from 'react-select';
import Modal from 'react-modal';
import Datepicker from '../utils/datepicker';
import EventDetails from './EventDetails';

class SessionHistory extends Component {
  constructor(props) {
    super(props);
    this.userHistoryService = new userHistoryService();
    this.listingService = new ListingService();
    this.state = {
      loading: false,
      analytics: [],
      analytics_filtered: [],
      number_students: 0,
      nbSessions_option: null,
      selected_nbSessions_option: null,
      offer_options: [],
      selected_offer_option: null,
      pages: 0,

      columns: [
        {
            Header: 'Student',
            id: 'student',
            show: true,
            accessor: (d) => d.student,
          },
        {
          Header: 'Phone',
          id: 'studentPhone',
          show: true,
          accessor: (d) => d.token.studentPhone,
        },
          {
            Header: 'Offer',
            id: 'offer',
            show: true,
            accessor: (d) => d.token.offer,
          },
          {
            Header: 'Subscription Start Date',
            id: 'subscription_start_date',
            show: true,
            accessor: (d) => {return d.token.startDate ? moment(d.token.startDate).local().format("DD-MM-YYYY HH:mm") : ''}
          },
          {
            Header: 'Subscription End Date',
            id: 'subscription_end_date',
            show: true,
            accessor: (d) => {return d.token.endDate ? moment(d.token.endDate)
            .local()
            .format("DD-MM-YYYY HH:mm") : ''}
          },
          {
            Header: 'Sessions Number',
            id: 'session',
            show: true,
            accessor: (d) => d.events.length,
          },
        {
            Header: 'Events',
            id: 'events',
            show: true,
            accessor: (d) => (
              <button
                onClick={() => this.openModal(d.id, d.events)}
                className="btn btn-link p-0"
              >
                see events details
              </button>
            ),
          },       
      ],
    };
  }

  componentDidMount() {
    this.getOfferFilterData();
    const columns = this.state.columns.map((column) => {
      const value = localStorage.getItem(column.id);
      if (value) {
        column.show = JSON.parse(value);
      }
      return column;
    });

    this.setState({
      columns: columns,
    });

    $(document).on('click', '.dropdown-menu', function(e) {
      e.stopPropagation();
    });
  }

  dateChange = (start, end, getData = true) => {
    this.setState({
      start_date: start,
      end_date: end,
    }, () => {
      if (getData) {
        this.getData();
      }
    });
  };
  
  getData = (state) => {
    const nbSessions = this.state.selected_nbSessions_option;
    const offerId = this.state.selected_offer_option && this.state.selected_offer_option.value;
    //if (nbSessions) {
      this.setState({loading: true});
      this.userHistoryService.analytics(
        this.state.start_date.toISOString(),
        this.state.end_date.toISOString(),
        nbSessions,
        offerId,
        state ? state.page : 0,
      ).then((data) => {
        this.setState({
          loading: false,
          analytics: data.students,
          analytics_filtered: data.students,
          number_students: data.students_count,
          pages: data.pages,
        });
      });
   // }
  };
  export = (state) => {
    const nbSessions = this.state.selected_nbSessions_option;
    const offerId = this.state.selected_offer_option && this.state.selected_offer_option.value;
    this.setState({loading: true});
    this.userHistoryService.export(
        this.state.start_date.toISOString(),
        this.state.end_date.toISOString(),
        nbSessions,
        offerId,
        state ? state.page : 0,
    ).then((data) => {
      this.setState({
        loading: false,
      });
    });
  };
  getOfferFilterData() {
    this.setState({loading: true});
    this.listingService
        .offers()
        .then((data) => {
          console.log('here offer', data)
          this.setState({
            loading: false,
            offer_options: data.token,
          });
        });
  }
  offerFilterChange = (selectedOption) => {
    this.setState(
        {selected_offer_option: selectedOption},
        () => this.getData(),
    );
  }
  searchTable = (event) => {
    this.setState({
      analytics_filtered: matchSorter(this.state.analytics, event.target.value,
        {keys: ['name']}),
    });
  };

  nbSessionsFilterChange = (event) => {
    this.setState(
      {selected_nbSessions_option: event.target.value},
      () => this.getData(),
    );
  };

  toggleColumn = (event) => {
    const column_id = event.target.value;
    const columns = this.state.columns.map((column) => {
      if (column.id === column_id) {
        column.show = !column.show;
        localStorage.setItem(column_id, column.show);
      }
      return column;
    });

    this.setState({
      columns: columns,
    });
  };

  openModal = (id, events) => {
    this.setState({
      selected: id,
      events: events,
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({modalIsOpen: false});
  };

  render() {
    const columns = this.state.columns;
    const analytics_filtered = this.state.analytics_filtered;
    const number_students = this.state.number_students;
    const selected_nbSessions_option = this.state.selected_nbSessions_option;
    const selected_offer_option = this.state.selected_offer_option;
    const offer_options = this.state.offer_options;
    return (
      <div>
        {this.state.loading && <Spinner/>}
        <div className="mb-2">
          <h2 className="title pull-left m-0">Analytics</h2>
          <Datepicker change={this.dateChange}/>
          <button className="btn btn-link border-0 pull-right"
                  aria-expanded="false"
                  onClick={this.export}
          > Export
          </button>
          <div className="clearfix"/>
          <p className="text-black-50">report generated just now</p>
        </div>
        <div className="border p-2 mb-3">
          <div className="row">
              <div className="col-md-6">
              <div className="form-group">
                  <label>Number of sessions (Required)</label>
                    <input
                      className="form-control border-1"
                      type="number"
                      value={selected_nbSessions_option}
                      placeholder="Filter by number of sessions"
                      onChange={this.nbSessionsFilterChange}
                    />
                </div>
              </div>
            <div className="col-md-6">
                <div className="form-group">
                <label>Offer (Required)</label>
                <Select
                    value={selected_offer_option}
                    onChange={this.offerFilterChange}
                    options={offer_options}
                    placeholder="Search offer"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="input-group py-2 border border-gray">
          <div className="input-group-prepend">
            <span className="input-group-text bg-transparent border-0"><i
              className="la la-search"/></span>
          </div>
          <input type="text" className="form-control border-0"
                 placeholder="Search"
                 onChange={this.searchTable}/>
          <input type="text" className="form-control border-0"
                 placeholder={"Total User "+ number_students}
                 disabled/>
          <div className="input-group-append">
            <div className="dropdown show">
              <button className="btn btn-link border-0 dropdown-toggle"
                      id="dropdownMenuLink"
                      data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">Manage
                Columns
              </button>
              <div className="dropdown-menu rounded-0 shadow"
                   aria-labelledby="dropdownMenuLink">
                {
                  columns.map((c) =>
                    <label key={c.id} className="dropdown-item"
                           htmlFor={c.id}>
                      <input
                        id={c.id} type="checkbox"
                        value={c.id}
                        checked={c.show}
                        onChange={this.toggleColumn}/> {c.Header}
                    </label>,
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <ReactTable
          data={analytics_filtered}
          columns={columns}
          PaginationComponent={Pagination}
          defaultPageSize={10}
          className=" -highlight"
        />
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Events Details"
          ariaHideApp={false}
        >
          <EventDetails events={this.state.events}/>
          <button onClick={this.closeModal}
                  className="btn btn-link float-right">
            <i className="la la-close"/>
          </button>

        </Modal>
      </div>

    );
  }
}

export default SessionHistory;

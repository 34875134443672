import React, {Component} from 'react';
import Chart from 'react-apexcharts';
import HomeService from '../../../services/video_analytics/home-service';
import Spinner from '../../utils/spinner';
import CountUp from 'react-countup';
import Datepicker from '../../utils/datepicker';
import Layout from '../../layouts/layout';

class HomeReactions extends Component {
  constructor(props) {
    super(props);
    this.homeService = new HomeService();
    this.state = {
      loading_chart: false,
      loading_overview: false,
      reactions: {
        'reactions': 0,
        'satisfied': 0,
        'unsatisfied': 0,
        'score': 0,
      },
      reactions_chart: {
        options: {
          chart: {
            id: 'reactions-chart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            type: 'datetime',
            labels: {
              format: 'dd/MM/yy',
            },
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy',
            },
          },
        },
        series: [],
      },
    };
  }

  componentDidMount() {
  }


    dateChange = (start, end) => {
      this.setState({
        start_date: start,
        end_date: end,
      }, ()=> {
        this.getOverviewData();
        this.getChartData();
      });
    };

    getOverviewData() {
      this.setState({loading_overview: true});
      this.homeService
          .reactions(
              this.state.start_date.toISOString(),
              this.state.end_date.toISOString(),
              this.state.type,
          )
          .then((data) => {
            this.setState({
              loading_overview: false,
              reactions: data,
            });
          });
    }

    getChartData() {
      this.setState({loading_chart: true});
      this.homeService
          .reactionsChart(
              this.state.start_date.format('YYYY-MM-DD'),
              this.state.end_date.format('YYYY-MM-DD'),
              this.state.type,
          )
          .then((data) => {
            const chart = this.state.reactions_chart;
            chart.series = [
              {
                name: 'Reactions',
                data: data.reactions,
              },
              {
                name: 'Satisfied Reactions',
                data: data.satisfied,
              },
              {
                name: 'Unsatisfied Reactions',
                data: data.unsatisfied,
              },
            ];
            this.setState({
              loading_chart: false,
              reactions_chart: chart,
            });
          });
    }

    render() {
      const reactions = this.state.reactions;
      return (
        <div>
          {this.state.loading_chart || this.state.loading_overview ? <Spinner /> : ''}
          <div className="mb-2">
            <h2 className="title pull-left m-0">Reactions</h2>
            <Datepicker change={this.dateChange}/>
            <div className="clearfix"/>
            <p className="text-black-50">report generated just now</p>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="card-widget">
                <div className="counter">
                  <p className="total">{reactions.reactions}</p>
                </div>
                <p className="title">Reactions</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-widget">
                <div className="counter">
                  <p className="total">
                    <CountUp end={reactions.satisfied}/>
                  </p>
                </div>
                <p className="title">Satisfied</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-widget">
                <div className="counter">
                  <p className="total">
                    <CountUp end={reactions.unsatisfied}/>
                  </p>
                </div>
                <p className="title">Unsatisfied</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-widget">
                <div className="counter">
                  <p className="total">
                    <CountUp end={reactions.score} suffix=" %"/>
                  </p>
                </div>
                <p className="title">Content score</p>
              </div>
            </div>
          </div>
          <div className="chart-container mt-5 border p-2">
            <Chart
              options={this.state.reactions_chart.options}
              series={this.state.reactions_chart.series}
              type="area"
              height="350"
            />
          </div>
        </div>

      );
    }
}

export default HomeReactions;

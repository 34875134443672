class ListingService {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  async teachers() {
    return fetch(`${this.apiUrl}/common/list/teachers`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }

  async divisions() {
    return fetch(`${this.apiUrl}/common/list/divisions`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }

  async levelSubjects(levelId) {
    return fetch(`${this.apiUrl}/common/list/levels/subjects/${levelId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }

  async subjectChapters(subjectId) {
    return fetch(`${this.apiUrl}/common/list/subjects/chapters/${subjectId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }

  async offers() {
    return fetch(`${this.apiUrl}/common/list/source-tokens`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }
}

export default ListingService;

import React, {Component} from 'react';
import Layout from '../../layouts/layout';
import moment from 'moment';
import ReportService from '../../../services/page_session/report-service';
import Spinner from '../../utils/spinner';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Chart from 'react-apexcharts';
import Datepicker from '../../utils/datepicker';

class ReportDetail extends Component {
  constructor(props) {
    super(props);
    this.reportService = new ReportService();
    this.state = {
      loading: false,
      user: {},
      analytics: {
        'ga:pageviews': 0,
        'ga:pageviewsPerSession': 0,
        'ga:avgTimeOnPage': 0,
        'ga:sessions': 0,
        'ga:avgSessionDuration': 0,
        'ga:daysSinceLastSession': 0,
      },
      views: [],
      // sessions:null,
      options: {
        chart: {
          id: 'basic-chart',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd/MM/yy',
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy',
          },
        },
      },
      series: [],
    };
  }

  componentDidMount() {
  }

    dateChange = (start, end) => {
      this.setState({
        start_date: start,
        end_date: end,
      }, ()=>{
        this.getReportData();
        this.getChartData();
        // this.getSessionsData();
        this.getViewsData();
      });
    };

    getReportData() {
      this.setState({loading: true});
      this.reportService
          .user(
              this.props.match.params.user_id,
              this.state.start_date.format('YYYY-MM-DD'),
              this.state.end_date.format('YYYY-MM-DD'),
          )
          .then((data) => {
            this.setState({
              loading: false,
              user: data.user,
              analytics: data.analytics,
            });
          });
    }

    getChartData() {
      this.setState({loading: true});
      this.reportService
          .chart(
              this.props.match.params.user_id,
              this.state.start_date.format('YYYY-MM-DD'),
              this.state.end_date.format('YYYY-MM-DD'),
          )
          .then((data) => {
            this.setState({
              loading: false,
              series: [
                {
                  name: 'Pages views',
                  data: data.views,
                },
                {
                  name: 'Sessions',
                  data: data.sessions,
                },
              ],
            });
          });
    }

    // no need, we cant get session start & end
    // getSessionsData(){
    //   this.setState({loading: true})
    //   this.reportService.sessions(this.props.match.params.user_id, this.state.start_date.format('YYYY-MM-DD'), this.state.end_date.format('YYYY-MM-DD')).then( data => {
    //     this.setState({
    //       loading: false,
    //       sessions: data
    //     });
    //   })
    // }
    getViewsData() {
      this.setState({loading: true});
      this.reportService
          .views(
              this.props.match.params.user_id,
              this.state.start_date.format('YYYY-MM-DD'),
              this.state.end_date.format('YYYY-MM-DD'),
          )
          .then((data) => {
            this.setState({
              loading: false,
              views: data,
            });
          });
    }

    render() {
      const user = this.state.user;
      const analytics = this.state.analytics;
      const views = this.state.views;
      return (
        <Layout title="Report Details">
          {this.state.loading && <Spinner/>}
          <div className="card-header px-0 py-2 bg-white border-0">
            <h2 className="title pull-left m-0 text-capitalize">
              {user.fullName}
            </h2>
            <Datepicker change={this.dateChange}/>
            <div className="clearfix"/>
            <p className="text-black-50">({user.divisionName})</p>
          </div>
          <div className="card-body">
            <div className="mb-2">
              <div className="row">
                <div className="col-md-6">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                                        #ID
                      <span className="pull-right">{user.id}</span>
                    </li>
                    <li className="list-group-item">
                                        Email
                      <span className="pull-right">{user.email}</span>
                    </li>
                    <li className="list-group-item">
                                        Phone
                      <span className="pull-right">{user.phone}</span>
                    </li>
                    <li className="list-group-item">
                                        Division
                      <span className="pull-right">{user.divisionName}</span>
                    </li>
                    <li className="list-group-item">
                                        Account created at
                      <span className="pull-right">{user.created_at}</span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        Page Views
                      <span className="badge badge-primary badge-pill">
                        {analytics['ga:pageviews']}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        Page views Per Session
                      <span className="badge badge-primary badge-pill">
                        {Math.round(analytics['ga:pageviewsPerSession'])}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        Average Time On Page
                      <span className="badge badge-primary badge-pill">
                        {moment
                            .utc(analytics['ga:avgTimeOnPage'] * 1000)
                            .format('HH:mm:ss')}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        Sessions
                      <span className="badge badge-primary badge-pill">
                        {analytics['ga:sessions']}
                      </span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        Average Session Duration
                      <span className="badge badge-primary badge-pill">
                        {moment
                            .utc(analytics['ga:avgSessionDuration'] * 1000)
                            .format('HH:mm:ss')}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="chart-container mt-5 border p-2">
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="area"
                  height="350"
                />
              </div>
              <h2>Page views</h2>
              <ReactTable
                data={views}
                columns={[
                  {
                    Header: 'Views',
                    columns: [
                      {
                        Header: 'Date',
                        id: 'date',
                        accessor: (d) => moment(d[0]).format('YYYY-MM-DD'),
                      },
                      {
                        Header: 'Title',
                        id: 'title',
                        accessor: (d) => d[1],
                      },
                      {
                        Header: 'Views No',
                        id: 'views',
                        accessor: (d) => d[2],
                      },
                      {
                        Header: 'Avg Time',
                        id: 'avgTimeOnPage',
                        accessor: (d) => moment.utc(d[3] * 1000).format('HH:mm:ss'),
                      },
                    ],
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
              />
            </div>
          </div>
        </Layout>
      );
    }
}

export default ReportDetail;

import React, {Component} from 'react';
import Spinner from '../utils/spinner';
import Layout from '../layouts/layout';
import SessionHistory from './SessionHistory';

class UserSessionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <Layout title="Chapters">
        {this.state.loading && <Spinner/>}
        <div className="card-body">
          <div className="tab-content">
            <div className="tab-pane fade show active" id="analytics">
              <SessionHistory/>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default UserSessionHistory;

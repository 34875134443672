import React, {Component} from 'react';
import $ from 'jquery';
import Spinner from '../../utils/spinner';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../../../assets/table.css';
import matchSorter from 'match-sorter';
import Pagination from '../../utils/table-pagination';
import DivisionsService from '../../../services/video_analytics/divisions-service';
import Modal from 'react-modal';
import Details from './details';
import Datepicker from '../../utils/datepicker';

class LevelsReactions extends Component {
  constructor(props) {
    super(props);
    this.levelsService = new DivisionsService();
    this.state = {
      loading: false,
      reactions: [],
      reactions_filtered: [],
      pages: 0,

      columns: [
        {
          Header: 'Name',
          id: 'name',
          show: true,
          accessor: (d) => (
            <button
              onClick={() => this.openModal(d.id)}
              className="btn btn-link p-0"
            >
              {d.name}
            </button>
          ),
        },
        {
          Header: 'reactions',
          id: 'reactions',
          show: true,
          accessor: (d) => d.analytics.reactions,
        },
        {
          Header: 'very satisfied',
          id: 'very_satisfied',
          show: true,
          accessor: (d) => d.analytics.very_satisfied,
        },
        {
          Header: 'satisfied',
          id: 'satisfied',
          show: true,
          accessor: (d) => d.analytics.satisfied,
        },
        {
          Header: 'neither satisfied nor dissatisfied',
          id: 'neither_satisfied_nor_dissatisfied',
          show: true,
          accessor: (d) => d.analytics.neither_satisfied_nor_dissatisfied,
        },
        {
          Header: 'unsatisfied',
          id: 'unsatisfied',
          show: true,
          accessor: (d) => d.analytics.unsatisfied,
        },
        {
          Header: 'not at all satisfied',
          id: 'not_at_all_satisfied',
          show: true,
          accessor: (d) => d.analytics.not_at_all_satisfied,
        },
        {
          Header: 'Score',
          id: 'score_per_total',
          show: true,
          accessor: (d) => Number(d.analytics.score),
          Cell: (row) => row.value + '%',
        },
      ],
    };
  }

  componentDidMount() {
    const columns = this.state.columns.map((column) => {
      const value = localStorage.getItem(column.id);
      if (value) {
        column.show = JSON.parse(value);
      }
      return column;
    });

    this.setState({
      columns: columns,
    });

    $(document).on('click', '.dropdown-menu', function(e) {
      e.stopPropagation();
    });
  }

    dateChange = (start, end, getData = true) => {
      this.setState({
        start_date: start,
        end_date: end,
      }, () => {
        if (getData) {
          this.getData();
        }
      });
    };

    getData = (state) => {
      this.setState({loading: true});
      this.levelsService
          .reactions(
              this.state.start_date.toISOString(),
              this.state.end_date.toISOString(),
              state ? state.page : 0,
          )
          .then((data) => {
            this.setState({
              loading: false,
              reactions: data.data,
              reactions_filtered: data.data,
              pages: data.pages,
            });
          });
    }

    searchTable = (event) => {
      this.setState({
        reactions_filtered: matchSorter(this.state.reactions, event.target.value, {keys: ['name']}),
      });
    }

    toggleColumn = (event) => {
      const column_id = event.target.value;
      const columns = this.state.columns.map((column) => {
        if (column.id === column_id) {
          column.show = !column.show;
          localStorage.setItem(column_id, column.show);
        }
        return column;
      });

      this.setState({
        columns: columns,
      });
    }

    openModal = (id) => {
      this.setState({
        selected: id,
        modalIsOpen: true,
      });
    }

    closeModal = () => {
      this.setState({modalIsOpen: false});
    }


    render() {
      const columns = this.state.columns;
      const reactions_filtered = this.state.reactions_filtered;
      return (
        <div>
          {this.state.loading && <Spinner/>}
          <div className="mb-2">
            <h2 className="title pull-left m-0">Reactions</h2>
            <Datepicker change={this.dateChange}/>
            <div className="clearfix"/>
            <p className="text-black-50">report generated just now</p>
          </div>
          <div className="input-group py-2 border border-gray">
            <div className="input-group-prepend">
              <span className="input-group-text bg-transparent border-0"><i
                className="la la-search"/></span>
            </div>
            <input type="text" className="form-control border-0" placeholder="Search"
              onChange={this.searchTable}/>
            <div className="input-group-append">

              <div className="dropdown show">
                <button className="btn btn-link border-0 dropdown-toggle" id="dropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Manage
                                Columns
                </button>
                <div className="dropdown-menu rounded-0 shadow"
                  aria-labelledby="dropdownMenuLink">
                  {
                    columns.map((c) =>
                      <label key={c.id} className="dropdown-item"
                        htmlFor={c.id}>
                        <input
                          id={c.id} type="checkbox"
                          value={c.id}
                          checked={c.show}
                          onChange={this.toggleColumn}/> {c.Header}
                      </label>,
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <ReactTable
            data={reactions_filtered}
            columns={columns}
            PaginationComponent={Pagination}
            defaultPageSize={10}
            className=" -highlight"

            // manual
            // pages={this.state.pages}
            // loading={this.state.loading}
            // onFetchData={this.getData}
            // sortable={false}
          />
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            contentLabel="Level Details"
            ariaHideApp={false}
          >
            <Details/>
            <button onClick={this.closeModal} className="btn btn-link float-right">
              <i className="la la-close"/>
            </button>

          </Modal>
        </div>

      );
    }
}

export default LevelsReactions;

import React, {Component} from 'react';
import $ from 'jquery';
import moment from 'moment';
import Spinner from '../../utils/spinner';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import '../../../assets/table.css';
import matchSorter from 'match-sorter';
import Pagination from '../../utils/table-pagination';
import TopService from '../../../services/video_analytics/top-service';
import Details from '../teachers/details';
import Modal from 'react-modal';
import Datepicker from '../../utils/datepicker';

class TopTeachers extends Component {
  constructor(props) {
    super(props);
    this.topService = new TopService();
    this.state = {
      loading: false,
      criteria: 'watched_time',
      analytics: [],
      analytics_filtered: [],
      generated_at: null,

      columns: [
        {
          Header: 'Name',
          id: 'fullName',
          show: true,
          accessor: (d) => (
            <button
              onClick={() => this.openModal(d.id)}
              className="btn btn-link p-0"
            >
              {d.fullName}
            </button>
          ),
        },

        {
          Header: 'Watched time',
          id: 'watched_time',
          show: true,
          accessor: (d) => moment.duration(d.analytics.watched_time, 'seconds').format('y [years], w [weeks], d [days], h [hours], m [minutes], s [seconds]'),
        },
        {
          Header: 'Views',
          id: 'views',
          show: true,
          accessor: (d) => d.analytics.videos_views,
        },
        {
          Header: 'Completed videos',
          id: 'completed_videos',
          show: true,
          accessor: (d) => d.analytics.completed_videos,
        },
        {
          Header: 'Watched time / Total watched time',
          id: 'watched_time_per_total',
          show: true,
          accessor: (d) => d.analytics.watched_time_per_total + '%',
        },
        {
          Header: 'Views / Total views',
          id: 'views_per_total',
          show: true,
          accessor: (d) => d.analytics.views_per_total + '%',
        },
        {
          Header: 'Completed videos / Total completed videos',
          id: 'completed_videos_per_total',
          show: true,
          accessor: (d) => d.analytics.completed_videos_per_total + '%',
        },
      ],
    };
  }

  componentDidMount() {
    const columns = this.state.columns.map((column) => {
      const value = localStorage.getItem(column.id);
      if (value) {
        column.show = JSON.parse(value);
      }
      return column;
    });

    this.setState({
      columns: columns,
    });

    $(document).on('click', '.dropdown-menu', function(e) {
      e.stopPropagation();
    });
  }

    dateChange = (start, end) => {
      this.setState({
        start_date: start,
        end_date: end,
      }, () => this.getData());
    };

    getData() {
      this.setState({loading: true});
      this.topService
          .teachers(
              this.state.start_date.toISOString(),
              this.state.end_date.toISOString(),
              this.state.criteria,
          )
          .then((data) => {
            this.setState({
              loading: false,
              analytics: data.analytics,
              analytics_filtered: data.analytics,
              generated_at: moment(data.generated_at).fromNow(),
            });
          });
    }

    searchTable = (event) => {
      this.setState({
        analytics_filtered: matchSorter(this.state.analytics, event.target.value, {keys: ['name']}),
      });
    }

    criteriaChange = (e) => {
      this.setState({loading: true});
      this.setState({
        criteria: e.target.value,
        loading: false,
      }, this.getData);
    };

    toggleColumn = (event) => {
      const column_id = event.target.value;
      const columns = this.state.columns.map((column) => {
        if (column.id === column_id) {
          column.show = !column.show;
          localStorage.setItem(column_id, column.show);
        }
        return column;
      });

      this.setState({
        columns: columns,
      });
    }

    openModal = (id) => {
      this.setState({
        selected: id,
        modalIsOpen: true,
      });
    }

    closeModal = () => {
      this.setState({modalIsOpen: false});
    }


    render() {
      const columns = this.state.columns;
      const analytics_filtered = this.state.analytics_filtered;
      return (
        <div>
          {this.state.loading && <Spinner/>}
          <div className="mb-2">
            <h2 className="title pull-left m-0">Top Teachers</h2>
            <Datepicker change={this.dateChange}/>
            <div className="clearfix"/>
            <p className="text-black-50">report generated {this.state.generated_at}</p>
          </div>
          <div className="form-group">
            <label>Criteria</label>
            <select className="form-control rounded-0" onChange={this.criteriaChange}>
              <option value="watched_time">Watched time</option>
              <option value="videos_views">Views</option>
              <option value="completed_videos">Completed videos</option>
            </select>
          </div>
          <div className="input-group py-2 border border-gray">
            <div className="input-group-prepend">
              <span className="input-group-text bg-transparent border-0"><i
                className="la la-search"/></span>
            </div>
            <input type="text" className="form-control border-0" placeholder="Search"
              onChange={this.searchTable}/>
            <div className="input-group-append">

              <div className="dropdown show">
                <button className="btn btn-link border-0 dropdown-toggle" id="dropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Manage
                                Columns
                </button>
                <div className="dropdown-menu rounded-0 shadow"
                  aria-labelledby="dropdownMenuLink">
                  {
                    columns.map((c) =>
                      <label key={c.id} className="dropdown-item"
                        htmlFor={c.id}>
                        <input
                          id={c.id} type="checkbox"
                          value={c.id}
                          checked={c.show}
                          onChange={this.toggleColumn}/> {c.Header}
                      </label>,
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <ReactTable
            data={analytics_filtered}
            columns={columns}
            PaginationComponent={Pagination}
            defaultPageSize={10}
            className=" -highlight"
          />
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            contentLabel="Teacher Details"
            ariaHideApp={false}
          >
            <Details/>
            <button onClick={this.closeModal} className="btn btn-link float-right">
              <i className="la la-close"/>
            </button>

          </Modal>
        </div>

      );
    }
}

export default TopTeachers;

class TopService {
  constructor() {
    this.api_url = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  // constructor() {}
  async teachers(startDate, endDate, criteria) {
    return fetch(
        `${this.api_url}/video-analytics/top/teachers/?startDate=${startDate}&endDate=${endDate}&criteria=${criteria}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async levels(startDate, endDate, criteria) {
    return fetch(
        `${this.api_url}/video-analytics/top/levels/?startDate=${startDate}&endDate=${endDate}&criteria=${criteria}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async subjects(startDate, endDate, criteria) {
    return fetch(
        `${this.api_url}/video-analytics/top/subjects/?startDate=${startDate}&endDate=${endDate}&criteria=${criteria}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async chapters(startDate, endDate, criteria) {
    return fetch(
        `${this.api_url}/video-analytics/top/chapters/?startDate=${startDate}&endDate=${endDate}&criteria=${criteria}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async videos(startDate, endDate, criteria) {
    return fetch(
        `${this.api_url}/video-analytics/top/videos/?startDate=${startDate}&endDate=${endDate}&criteria=${criteria}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }
}

export default TopService;

import React, {Component} from 'react';
import AuthService from '../../services/auth-service';
import Spinner from '../utils/spinner';
import {Link} from 'react-router-dom';

class Login extends Component {
  constructor(props) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      loading: false,
      error: null,
      username: '',
      password: '',
    };
  }

  componentDidMount() {
  }

    handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value});
    };

    handleSubmit = (e) => {
      this.setState({loading: true});
      e.preventDefault();
      this.authService.login({
        username: this.state.username,
        password: this.state.password,
      }).then((error) => {
        this.setState({
          error: error,
          loading: false,
        });
      });
    };

    render() {
      return (
        <div className="container main my-3">
          <div className="branding">
            <Link to="/" className="navbar-brand d-block text-center">
              <img src="/assets/logo.svg" alt=""/>
            </Link>
          </div>
          <div className="d-block mt-md-5 mx-auto w-50">
            <div className="content card" style={{minHeight: '500px'}}>
              {this.state.loading && <Spinner/>}
              <div className="card-header px-0 py-2 bg-white border-0 text-center my-3">
                <div className="mb-2">
                  <h2 className="title m-0">Login</h2>
                  <div className="clearfix"/>
                  <p className="text-black-50">
                                    using your Takiacademy admin account
                  </p>
                </div>
              </div>
              < div
                className="card-body mt-1">
                {
                  this.state.error && <div className="alert alert-danger">
                    {this.state.error}
                  </div>
                }
                <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control form-control-lg rounded-0"
                      placeholder="Username"
                      name="username"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control form-control-lg rounded-0"
                      placeholder="Password"
                      name="password"
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-lg btn-primary rounded-0 w-100">
                                    Login
                  </button>
                </form>
              </div>


            </div>
          </div>
        </div>
      );
    }
}

export default Login;

import React, {Component} from 'react';
import Layout from '../layouts/layout';
import Spinner from '../utils/spinner';
import UnderConstruction from '../utils/under-construction';
import CountUp from 'react-countup';
import Chart from 'react-apexcharts';
import ReactTable from 'react-table';
import Pagination from '../utils/table-pagination';
import Datepicker from '../utils/datepicker';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 0,
      columns: [
        {
          Header: 'Offer',
          id: 'offer',
          show: true,
          accessor: (d) => d.name,
        },
        {
          Header: 'Earnings',
          id: 'earnings',
          show: true,
          accessor: (d) => d.earnings,
        },
        {
          Header: 'Active subscriptions',
          id: 'active',
          show: true,
          accessor: (d) => d.subscriptions.active,
        },
        {
          Header: 'Expired subscriptions',
          id: 'expired',
          show: true,
          accessor: (d) => d.subscriptions.expired,
        },
      ],
      chart: {
        series: [55, 13, 43, 22],
        options: {
          chart: {
            width: 380,
            type: 'pie',
          },
          labels: ['Silver', 'Gold', 'Milloul lilekher', 'Others'],
          theme: {
            monochrome: {
              enabled: true,
            },
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
        },
      },
    };
  }

  componentDidMount() {
  }

  dateChange = (start, end) => {
    this.setState({
      start_date: start,
      end_date: end,
    });
  };

  render() {
    const analytics = {
      offers: 23,
      subscriptions: 15368,
      earnings: 800,
    };
    const tableData = [
      {
        name: 'Silver',
        subscriptions: {
          active: 15,
          expired: 78,
        },
        earnings: '374K',
      },
      {
        name: 'Gold',
        subscriptions: {
          active: 55,
          expired: 98,
        },
        earnings: '874K',
      },
    ];
    const columns = this.state.columns;
    return (
      <Layout title="Dashboard">
        <UnderConstruction/>
        {this.state.loading && <Spinner/>}
        <div className="card-header px-0 py-2 bg-white border-0">

        </div>
        <div className="card-body">

          <div className="mb-2">
            <h2 className="title pull-left m-0">Dashboard</h2>
            <Datepicker change={this.dateChange}/>
            <div className="clearfix"/>
            <p className="text-black-50">report generated just now</p>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="card-widget">
                <div className="counter">
                  <p className="total"><CountUp end={analytics.offers}/></p>
                </div>
                <p className="title">Offers</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-widget">
                <div className="counter">
                  <p className="total"><CountUp end={analytics.subscriptions}/></p>
                </div>
                <p className="title">Subscriptions</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-widget">
                <div className="counter">
                  <p className="total"><CountUp end={analytics.earnings}/></p>
                </div>
                <p className="title">Earnings</p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-8">
              <div className="card shadow-none p-0">
                <div className="card-header">
                  Top Offers
                </div>
                <div className="card-body p-0">
                  <ReactTable
                    data={tableData}
                    columns={columns}
                    PaginationComponent={Pagination}
                    defaultPageSize={10}
                    className=" -highlight"

                    manual
                    pages={this.state.pages}
                    loading={this.state.loading}
                    onFetchData={this.getData}
                    sortable={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card shadow-none p-0">
                <div className="card-header">
                  Subscriber per offer
                </div>
                <div className="card-body p-0">
                  <Chart
                    options={this.state.chart.options}
                    series={this.state.chart.series}
                    type="pie"
                    height="350"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Home;

class SubjectsService {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  async analytics(startDate, endDate, divisionId, page) {
    return fetch(
        `${this.apiUrl}/video-analytics/subjects/progress-analytics/?startDate=${startDate}&endDate=${endDate}&divisionId=${divisionId}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async reactions(startDate, endDate, divisionId, page) {
    return fetch(
        `${this.apiUrl}/video-analytics/subjects/reaction-analytics/?startDate=${startDate}&endDate=${endDate}&divisionId=${divisionId}&page=${page}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }

  async subject(subjectId, startDate, endDate) {
    return fetch(
        `${this.apiUrl}/video-analytics/subjects/${subjectId}?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': this.token,
          },
        })
        .then((response) => {
          return response.json();
        });
  }
}

export default SubjectsService;

import React, {Component} from 'react';
import Layout from '../layouts/layout';
import Chart from 'react-apexcharts';
import HomeService from '../../services/page_session/home-service';
import Spinner from '../utils/spinner';
import Datepicker from '../utils/datepicker';
import moment from 'moment';

class Home extends Component {
  constructor(props) {
    super(props);
    this.homeService = new HomeService();
    this.state = {
      loading_chart: false,
      loading_overview: false,
      type: 'Teacher',
      analytics: {
        'ga:pageviews': 0,
        'ga:sessions': 0,
        'ga:avgTimeOnPage': 0,
        'ga:avgSessionDuration': 0,
      },
      options: {
        chart: {
          id: 'basic-chart',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'dd/MM/yy',
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy',
          },
        },
      },
      series: [],
    };
  }
  componentDidMount() {
  }

  dateChange = (start, end) => {
    this.setState({
      start_date: start,
      end_date: end,
    }, () =>{
      this.getOverviewData();
      this.getChartData();
    });

  };

  getOverviewData() {
    this.setState({loading_overview: true});
    this.homeService
        .overview(
            this.state.start_date.format('YYYY-MM-DD'),
            this.state.end_date.format('YYYY-MM-DD'),
            this.state.type.toLowerCase(),
        )
        .then((data) => {
          this.setState({
            loading_overview: false,
            analytics: data,
          });
        });
  }
  getChartData() {
    this.setState({loading_chart: true});
    this.homeService
        .chart(
            this.state.start_date.format('YYYY-MM-DD'),
            this.state.end_date.format('YYYY-MM-DD'),
            this.state.type.toLowerCase(),
        )
        .then((data) => {
          this.setState({
            loading_chart: false,
            series: [
              {
                name: 'Pages views',
                data: data.views,
              },
              {
                name: 'Sessions',
                data: data.sessions,
              },
            ],
          });
        });
  }
  tabChange = (value) => {
    this.setState(
        {
          type: value,
        },
        () => {
          this.getOverviewData();
          this.getChartData();
        },
    );
  };
  render() {
    const analytics = this.state.analytics;
    return (
      <Layout title="Dashboard">
        {this.state.loading_chart || this.state.loading_overview ? <Spinner /> : ''}
        <div className="card-header px-0 py-2 bg-white border-0">
          <ul className="nav nav-tabs" id="tab" role="tablist">
            <li className="nav-item">
              <button
                className={ this.state.type === 'Teacher' ? 'btn-link nav-link active' : 'btn-link nav-link'}
                onClick={() => this.tabChange('Teacher')}
              >
                Teachers
              </button>
            </li>
            <li className="nav-item">
              <button
                className={ this.state.type === 'Pupil' ? 'btn-link nav-link active' : 'btn-link nav-link'}
                onClick={() => this.tabChange('Pupil')}
              >
                Students
              </button>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div className="mb-2">
            <h2 className="title pull-left m-0">Overview</h2>

            <Datepicker
              change={this.dateChange}
            />
            <div className="clearfix"/>
            <p className="text-black-50">report generated just now</p>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="card-widget">
                <div className="counter">
                  <p className="total">{analytics['ga:pageviews']}</p>
                </div>
                <p className="title">Page Views</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-widget">
                <div className="counter">
                  <p className="total">
                    {moment
                        .utc(analytics['ga:avgTimeOnPage'] * 1000)
                        .format('HH:mm:ss')}
                  </p>
                </div>
                <p className="title">Average time on page</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-widget">
                <div className="counter">
                  <p className="total">{analytics['ga:sessions']}</p>
                </div>
                <p className="title">Sessions</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card-widget">
                <div className="counter">
                  <p className="total">
                    {moment
                        .utc(analytics['ga:avgSessionDuration'] * 1000)
                        .format('HH:mm:ss')}
                  </p>
                </div>
                <p className="title">Average session duration</p>
              </div>
            </div>
          </div>
          <div className="chart-container mt-5 border p-2">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="area"
              height="350"
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export default Home;

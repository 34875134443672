import React, {Component} from 'react';
import Layout from '../layouts/layout';
import Spinner from '../utils/spinner';
import $ from 'jquery';
import Select from 'react-select';
import ReactTable from 'react-table';
import Pagination from '../utils/table-pagination';
// import matchSorter from "match-sorter";
import HomeService from '../../services/live_session/home';
import ListingService from '../../services/common/listing';
import Datepicker from '../utils/datepicker';
import moment from 'moment';

class Home extends Component {
  constructor(props) {
    super(props);
    this.homeService = new HomeService();
    this.listingService = new ListingService();
    this.state = {
      data_loading: false,
      filter_loading: false,
      analytics: [],
      analytics_filtered: [],
      teacher_options: [],
      selected_teacher_option: null,
      pages: 0,

      columns: [
        {
          Header: 'Event Id',
          id: 'event_id',
          show: true,
          accessor: 'event_id',
        },
        {
          Header: 'Title',
          id: 'title',
          show: true,
          accessor: (d) => d.title.substr(d.title.indexOf('_') + 1,
              d.title.length),
        },
        {
          Header: 'Instructor',
          id: 'instructor',
          show: true,
          accessor: (d) => <span
            className="badge badge-info">{d.instructor}</span>,
        },
        {
          Header: 'Webinar account',
          id: 'webinar_account_email',
          show: true,
          accessor: 'webinar_account_email',
        },
        {
          Header: 'Scheduled date and time',
          columns: [
            {
              Header: 'Beginning',
              id: 'programmed_start_date',
              show: true,
              accessor: 'programmed_start_date',
            },
            {
              Header: 'Ending',
              id: 'programmed_end_date',
              show: true,
              accessor: 'programmed_end_date',
            },
            {
              Header: 'Duration',
              id: 'programmed_duration',
              show: true,
              accessor: (d) => moment.duration(d.programmed_duration,
                  'seconds').format('h [hours], m [minutes], s [seconds]'),
            },
          ],
        },
        // {
        //     Header: "Follow-up of the Gotowebinar Session",
        //     columns: [
        {
          Header: 'Number of Sessions',
          id: 'sessions_number',
          show: true,
          accessor: (d) => d.sessions.length,
        },
        {
          Header: 'Date and time done',
          columns: [
            {
              Header: 'Beginning',
              id: 'session_start_date',
              show: true,
              accessor: 'session_start_date',
            },
            {
              Header: 'Ending',
              id: 'session_end_date',
              show: true,
              accessor: 'session_end_date',
            },
            {
              Header: 'Duration',
              id: 'session_duration',
              show: true,
              accessor: (d) => moment.duration(d.session_duration, 'seconds').
                  format('h [hours], m [minutes], s [seconds]'),
            },
          ],
        },
        {
          Header: 'Student Rating',
          columns: [
            {
              Header: 'Average',
              id: 'count_average_rate',
              show: true,
              accessor: 'count_average_rate',
            },
            {
              Header: 'Rating',
              id: 'count_rate',
              show: true,
              accessor: 'count_rate',
            },
            {
              Header: 'Have not rated yet',
              id: 'count_didnt_rate_yet',
              show: true,
              accessor: 'count_didnt_rate_yet',
            },
            {
              Header: 'Do not want to rate',
              id: 'count_not_want_to_rate',
              show: true,
              accessor: 'count_not_want_to_rate',
            },
            {
              Header: 'Comments',
              id: 'count_observations',
              show: true,
              accessor: 'count_observations',
            },
          ],
        },
        {
          Header: 'Presence of students',
          columns: [
            {
              Header: 'Registered',
              id: 'count_registered',
              show: true,
              accessor: 'count_registered',
            },
            {
              Header: 'Present',
              id: 'count_assisted',
              show: true,
              accessor: 'count_assisted',
            },
            {
              Header: 'Absent',
              id: 'count_absent',
              show: true,
              accessor: 'count_absent',
            },
            {
              Header: 'Cheaters',
              id: 'count_cheaters',
              show: true,
              accessor: 'count_cheaters',
            },
          ],
        },
        // ]
        // },
      ],
    };
  }
  export = (state) => {
    this.setState({loading: true});
    this.homeService.export(
        this.state.start_date.toISOString(),
        this.state.end_date.toISOString(),
        state ? state.page : 0,
    ).then((data) => {
      this.setState({
        loading: false,
      });
    });
  };
  componentDidMount() {
    this.getTeacherFilterData();
    const columns = this.state.columns.map((column) => {
      const value = localStorage.getItem(column.id);
      if (value) {
        column.show = JSON.parse(value);
      }
      return column;
    });

    this.setState({
      columns: columns,
    });

    $(document).on('click', '.dropdown-menu', function(e) {
      e.stopPropagation();
    });
  }

  dateChange = (start, end) => {
    this.setState({
      start_date: start,
      end_date: end,
    }, () =>
      this.getData(),
    );
  };

  getData = (state) => {
    this.setState({data_loading: true});
    this.homeService.list(
        this.state.start_date.toISOString(),
        this.state.end_date.toISOString(),
        this.state.selected_teacher_option &&
      this.state.selected_teacher_option.value,
      state ? state.page : 0,
    ).then((data) => {
      this.setState({
        data_loading: false,
        analytics: data.events,
        analytics_filtered: data.events,
        pages: data.pages,

      });
    });
  };

  getTeacherFilterData() {
    this.setState({filter_loading: true});
    this.listingService.teachers().then((data) => {
      this.setState({
        filter_loading: false,
        teacher_options: data,
      });
    });
  }

  // searchTable = (event) => {
  //     this.setState({
  //         analytics_filtered: matchSorter(this.state.analytics, event.target.value, {keys: ['title']})
  //     })
  // }

  toggleColumn = (event) => {
    const column_id = event.target.value;
    const columns = this.state.columns.map((column) => {
      if (column.id === column_id) {
        column.show = !column.show;
        localStorage.setItem(column_id, column.show);
      }
      if (column.columns) {
        column.columns.map((child) => {
          if (child.id === column_id) {
            child.show = !child.show;
            localStorage.setItem(column_id, child.show);
          }
        });
      }
      return column;
    });

    this.setState({
      columns: columns,
    });
  };
  teacherFilterChange = (selectedOption) => {
    this.setState(
        {selected_teacher_option: selectedOption},
        () => this.getData(),
    );
  };

  render() {
    const columns = this.state.columns;
    const analytics_filtered = this.state.analytics_filtered;
    const selected_teacher_option = this.state.selected_teacher_option;
    const teacher_options = this.state.teacher_options;
    return (
      <Layout title="Live sessions monitoring">
        {this.state.data_loading || this.state.filter_loading ? <Spinner/> : ''}
        <div className="card-header px-0 py-2 bg-white border-0">

        </div>
        <div className="card-body">

          <div className="mb-2">
            <h2 className="title pull-left m-0">Analytics</h2>
            <Datepicker
              change={this.dateChange}
            />
            <button className="btn btn-link border-0 pull-right"
                    aria-expanded="false"
                    onClick={this.export}
            > Export
            </button>
            <div className="clearfix"/>
            <p className="text-black-50">report generated just now</p>
          </div>
          <div className="border p-2 mb-3">
            <div className="form-group">
              <label>Teacher (optional)</label>
              <Select
                value={selected_teacher_option}
                onChange={this.teacherFilterChange}
                options={teacher_options}
                placeholder="Search teachers"
              />
            </div>
          </div>
          <div className="input-group py-2 border border-gray">
            {/* <div className="input-group-prepend">
                            <span className="input-group-text bg-transparent border-0"><i
                                className="la la-search"/></span>
                        </div>
                        <input type="text" className="form-control border-0" placeholder="Search"
                               onChange={this.searchTable}/>*/}
            <input type="text" className="form-control border-0 bg-white"
              disabled/>
            <div className="input-group-append">

              <div className="dropdown show float-right">
                <button className="btn btn-link border-0 dropdown-toggle"
                  id="dropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">Manage
                  Columns
                </button>
                <div className="dropdown-menu rounded-0 shadow"
                  aria-labelledby="dropdownMenuLink">
                  {
                    columns.map((column) => (
                      <span>
                        {column.columns ?
                            column.columns.map((child) =>
                              <label key={child.id} className="dropdown-item"
                                htmlFor={child.id}>
                                <input
                                  id={child.id} type="checkbox"
                                  value={child.id}
                                  checked={child.show}
                                  onChange={this.toggleColumn}/> {child.Header}
                              </label>,
                            ) :
                            <label key={column.id} className="dropdown-item"
                              htmlFor={column.id}>
                              <input
                                id={column.id} type="checkbox"
                                value={column.id}
                                checked={column.show}
                                onChange={this.toggleColumn}/> {column.Header}
                            </label>}
                      </span>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          {this.state.start_date && <ReactTable
            data={analytics_filtered}
            columns={columns}
            manual
            pages={this.state.pages}
            loading={this.state.data_loading}
            onFetchData={this.getData}
            sortable={false}
            PaginationComponent={Pagination}
            defaultPageSize={10}
            className=" -highlight"
          />}
        </div>


      </Layout>
    );
  }
}

export default Home;

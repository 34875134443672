import React, {Component} from 'react';
import moment from 'moment';
import 'bootstrap-daterangepicker';
import Chart from 'react-apexcharts';
import HomeService from '../../../services/video_analytics/home-service';
import Spinner from '../../utils/spinner';
import CountUp from 'react-countup';
import Datepicker from '../../utils/datepicker';
import Layout from '../../layouts/layout';

class HomeAnalytics extends Component {
  constructor(props) {
    super(props);
    this.homeService = new HomeService();
    this.state = {
      loading_chart: false,
      loading_overview: false,
      analytics: {
        'videos': 0,
        'videos_views': 0,
        'completed_videos': 0,
        'watched_time': 0,
      },
      analytics_chart: {
        options: {
          chart: {
            id: 'analytics-chart',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            type: 'datetime',
            labels: {
              format: 'dd/MM/yy',
            },
          },
          tooltip: {
            enabled: true,
            x: {
              format: 'dd/MM/yy',
            },
            y: {
              formatter: function(val, point) {
                if (point.seriesIndex === 1) {
                  return moment.duration(val, 'seconds').
                    format(
                      'y [years], w [weeks], d [days], h [hours], m [minutes], s [seconds]');
                }
                return val;
              },
            },
          },
        },
        series: [],
      },
    };
  }

  componentDidMount() {
  }

  dateChange = (start, end) => {
    this.setState({
      start_date: start,
      end_date: end,
    }, () => {
      this.getOverviewData();
      this.getChartData();
    });
  };

  getOverviewData() {
    this.setState({loading_overview: true});
    this.homeService.analytics(
      this.state.start_date.toISOString(),
      this.state.end_date.toISOString(),
      this.state.type,
    ).then((data) => {
      this.setState({
        loading_overview: false,
        analytics: data,
      });
    });
  }

  getChartData() {
    this.setState({loading_chart: true});
    this.homeService.analyticsChart(
      this.state.start_date.format('YYYY-MM-DD'),
      this.state.end_date.format('YYYY-MM-DD'),
      this.state.type,
    ).then((data) => {
      const chart = this.state.analytics_chart;
      chart.series = [
        {
          name: 'Views',
          data: data.views,
        },
        {
          name: 'Watched time',
          data: data.watched_time,
        },
        {
          name: 'Completed views',
          data: data.completed_views,
        },
      ];
      this.setState({
        loading_chart: false,
        analytics_chart: chart,
      });
    });
  }

  render() {
    const analytics = this.state.analytics;
    return (
      <div>
        {this.state.loading_chart || this.state.loading_overview ? <Spinner /> : ''}
        <div className="mb-2">
          <h2 className="title pull-left m-0">Analytics</h2>
          <Datepicker change={this.dateChange}/>
          <div className="clearfix"/>
          <p className="text-black-50">report generated just now</p>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="card-widget">
              <div className="counter">
                <p className="total"><CountUp end={analytics.videos}/></p>
              </div>
              <p className="title">Videos</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card-widget">
              <div className="counter">
                <p className="total">
                  <CountUp end={analytics.videos_views}/>
                </p>
              </div>
              <p className="title">Videos views</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card-widget">
              <div className="counter">
                <p className="total">
                  <CountUp end={analytics.completed_videos}/>
                </p>
              </div>
              <p className="title">Completed videos</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card-widget">
              <div className="counter">
                <p className="total" style={{'fontSize': '20px'}}>
                  {moment.duration(analytics.watched_time, 'seconds').
                    format(
                      'y [years], w [weeks], d [days], h [hours], m [minutes], s [seconds]',
                      {
                        trim: false,
                      })}
                </p>
              </div>
              <p className="title">Watched time</p>
            </div>
          </div>
        </div>
        <div className="chart-container mt-5 border p-2">
          <Chart
            options={this.state.analytics_chart.options}
            series={this.state.analytics_chart.series}
            type="area"
            height="350"
          />
        </div>
      </div>
    );
  }
}

export default HomeAnalytics;

class ReportService {
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.token = localStorage.getItem('token');
  }

  async users(startDate, endDate, type) {
    return fetch(`${this.apiUrl}/session-analytics/users/type/${type}/?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }

  async user(userId, startDate, endDate) {
    return fetch(`${this.apiUrl}/session-analytics/users/${userId}?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }

  async chart(userId, startDate, endDate) {
    return fetch(`${this.apiUrl}/session-analytics/users/${userId}/chart/?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }

  // async sessions(userId, startDate, endDate) {
  //   return fetch(`${this.api_url}/session-analytics/users/${userId}/sessions/?startDate=${startDate}&endDate=${endDate}`)
  //       .then((response) => {
  //         return response.json();
  //       });
  // }

  async views(userId, startDate, endDate) {
    return fetch(`${this.apiUrl}/session-analytics/users/${userId}/views/?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.token,
      },
    })
        .then((response) => {
          return response.json();
        });
  }
}

export default ReportService;
